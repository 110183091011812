import './bootstrap'

import { createApp, h } from 'vue'
import { createInertiaApp, usePage } from '@inertiajs/inertia-vue3'
import { InertiaProgress } from '@inertiajs/progress'
import { Inertia } from '@inertiajs/inertia'
import can from '@/Directives/can'
import usePermissions from './Composables/usePermissions'

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'ACC'

const permissions = usePermissions()
Inertia.on('navigate', () => {
    let newPermissions = usePage().props.value.permissions

    if(newPermissions !== null){
        permissions.set(newPermissions ?? [])
    }
})

createInertiaApp({
    title   : (title) => `${title} - ${appName}`,
    resolve : async name => {
        if (import.meta.env.DEV) {
            return (await import(`./Pages/${name}.vue`)).default
        } else {
            let pages = import.meta.glob('./Pages/**/*.vue')

            return pages[`./Pages/${name}.vue`]().then(module => module.default)
        }
    },
    setup: ({ el, app, props, plugin }) => {
        return createApp({ render: () => h(app, props) })
            .use(plugin)
            .mixin({ 
                methods: { 
                    // eslint-disable-next-line no-undef
                    route,
                } 
            })
            .directive('can', can)
            .mount(el)
    }
})

InertiaProgress.init({ color: '#4B5563' })
