import { computed, ref } from 'vue'
import { wrap } from '../Helpers/array'

/**
 * Checks if the page permissions include the requested permission
 * 
 * @param {string} permission 
 * @param {string|null} type 
 * @returns {boolean}
 */
const hasPermission = (permission, type = null) => (permissions.value).indexOf(type == null ? permission : `${permission} ${type}`) !== -1

const permissions = ref([])
export default function usePermissions(){

    return {
        current: permissions,

        /**
         * Replace the existing permissions
         * 
         * @param {Array} newPermissions 
         * @returns 
         */
        set: (newPermissions) => permissions.value = newPermissions,

        /**
         * Checks if the user has an individual permission
         * 
         * @param {String|Array} permission 
         * @returns {computed}
         */
        has: (permission) => {
            permission = wrap(permission)

            if(permission[0] === undefined){
                return true
            }

            return hasPermission(permission[0], permission[1] ?? null)
        },

        /**
         * Checks if the user has any of the provided permissions
         * 
         * @param {Array} permission 
         * @returns {computed}
         */
        hasAny: function(permission){
            return computed(() => wrap(permission).some((individualPermission) => this.has(individualPermission).value))
        },

        /**
         * Checks if the user has all of the provided permissions
         * 
         * @param {Array} permission 
         * @returns {computed}
         */
        hasAll: function(permission){
            return computed(() => wrap(permission).every((individualPermission) => this.has(individualPermission).value))
        }
    }

}