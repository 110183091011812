/**
 * Wraps primitives in arrays
 * 
 * @param {mixed} value 
 * @returns 
 */
const wrap = (value) => !Array.isArray(value) ? [value] : value

export {
    wrap
}