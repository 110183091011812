import { library } from '@fortawesome/fontawesome-svg-core'

import { faArrowUp, faTrashCan, faPenToSquare, faEye, faBan, faCircleCheck, faCircleXmark, faCaretRight, faCaretDown, faXmark, faFileArrowDown, faSort, faAddressBook, faPeopleGroup, faFileLines, faMoneyBill, faPeopleArrows, faUserPlus, faKey, faMagnifyingGlass, faCircleInfo } from '@fortawesome/free-solid-svg-icons'

library.add(
    faArrowUp,
    faTrashCan,
    faPenToSquare,
    faEye,
    faBan,
    faCircleCheck,
    faCircleXmark,
    faCaretRight,
    faCaretDown,
    faXmark,
    faFileArrowDown,
    faSort,
    faAddressBook,
    faPeopleGroup,
    faFileLines,
    faMoneyBill,
    faPeopleArrows,
    faUserPlus,
    faKey,
    faMagnifyingGlass,
    faCircleInfo
)