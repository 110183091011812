import { watch } from 'vue'
import usePermissions from '@/Composables/usePermissions'

const can = {
    mounted: (el, binding) => {
        const { has, current } = usePermissions()
    
        //replace the element itself with a comment (similar to how v-if works)
        const commentElement = document.createComment('v-can')
        const parent = el.parentNode
    
        watch([() => binding.value, current], () => {
            const permission = binding.value === undefined || has(binding.value)
    
            if(parent){ //prevents errors for the parent not existing
                if(permission){
    
                    if(parent.contains(commentElement)){
                        parent.replaceChild(el, commentElement)
                    }
    
                } else {
    
                    if(parent.contains(el)){
                        parent.replaceChild(commentElement, el)
                    }
    
                }
            }
        }, { deep: true, immediate: true })
    }
}

export default can